export const config = {
    DEBUG: process.env.NEXT_PUBLIC_STAGE === "local",
    STAGE: process.env.NEXT_PUBLIC_STAGE as "local" | "dev" | "prod",
    FRONTEND_URL: process.env.NEXT_PUBLIC_FRONTEND_URL,
    S3_URL: process.env.NEXT_PUBLIC_S3_URL,
    GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
    FACEBOOK_APP_ID: process.env.FACEBOOK_APP_ID,
    NODE_ENV: process.env.NODE_ENV,
    AUTHORIZATION_HEADER: process.env.AUTHORIZATION_HEADER || "X-Authorization",
    SUPPORT_EMAIL: "info@energeticka-expedice.cz",
};
