// this file is generated automatically, do not change anything manually in the contents of this file

export const ICONS = {
    "arrow-left": { w: 448, h: 512 },
    "arrow-right": { w: 448, h: 512 },
    "calendar": { w: 448, h: 512 },
    "camera": { w: 512, h: 512 },
    "caretDown": { w: 320, h: 512 },
    "clock": { w: 512, h: 512 },
    "bars": { w: 448, h: 512 },
    "check": { w: 512, h: 512 },
    "chevronDown": { w: 512, h: 512 },
    "chevronLeft": { w: 384, h: 512 },
    "chevronsLeft": { w: 512, h: 512 },
    "chevronRight": { w: 384, h: 512 },
    "chevronsRight": { w: 512, h: 512 },
    "chevronUp": { w: 512, h: 512 },
    "cloud": { w: 640, h: 512 },
    "copy": { w: 512, h: 512 },
    "ellipsis-vertical": { w: 128, h: 512 },
    "file": { w: 384, h: 512 },
    "imageFile": { w: 384, h: 512 },
    "triangle-exclamation": { w: 512, h: 512 },
    "user": { w: 448, h: 512 },
    "videoFile": { w: 384, h: 512 },
    "xmark": { w: 384, h: 512 },
    "xmarkLarge": { w: 448, h: 512 },
    "circle-info": { w: 512, h: 512 },
    "filter": { w: 512, h: 512 },
    "pen": { w: 512, h: 512 },
    "table": { w: 512, h: 512 },
    "file-arrow-down": { w: 384, h: 512 },
    "table-columns": { w: 640, h: 512 },
    "search": { w: 512, h: 512 },
    "file-download": { w: 384, h: 512 },
    "trash": { w: 448, h: 512 },
    "warning": { w: 512, h: 512 },
    "faPro_light.camera-retro": { w: 512, h: 512 },
    "faPro_light.graduation-cap": { w: 640, h: 512 },
    "faPro_light.key": { w: 512, h: 512 },
    "faPro_light.lightbulb": { w: 384, h: 512 },
    "faPro_light.list-check": { w: 512, h: 512 },
    "faPro_light.lock-keyhole": { w: 448, h: 512 },
    "faPro_light.map": { w: 576, h: 512 },
    "faPro_light.party-horn": { w: 512, h: 512 },
    "faPro_light.refrigerator": { w: 384, h: 512 },
    "faPro_light.square-poll-vertical": { w: 448, h: 512 },
    "faPro_light.timer": { w: 512, h: 512 },
    "faPro_light.user-check": { w: 640, h: 512 },
    "faPro_light.user-xmark": { w: 640, h: 512 },
    "faPro_light.video": { w: 576, h: 512 },
    "faPro_light.washing-machine": { w: 448, h: 512 },
    "faPro_regular.angle-right": { w: 320, h: 512 },
    "faPro_regular.arrow-down-to-line": { w: 384, h: 512 },
    "faPro_regular.arrow-left": { w: 448, h: 512 },
    "faPro_regular.arrow-right": { w: 448, h: 512 },
    "faPro_regular.arrow-right-from-bracket": { w: 512, h: 512 },
    "faPro_regular.backward-step": { w: 320, h: 512 },
    "faPro_regular.bars": { w: 448, h: 512 },
    "faPro_regular.bolt-lightning": { w: 384, h: 512 },
    "faPro_regular.clock": { w: 512, h: 512 },
    "faPro_regular.eye": { w: 576, h: 512 },
    "faPro_regular.info": { w: 192, h: 512 },
    "faPro_regular.lightbulb": { w: 384, h: 512 },
    "faPro_regular.list-check": { w: 512, h: 512 },
    "faPro_regular.loader": { w: 512, h: 512 },
    "faPro_regular.map": { w: 576, h: 512 },
    "faPro_regular.pen": { w: 512, h: 512 },
    "faPro_regular.person": { w: 320, h: 512 },
    "faPro_regular.play": { w: 384, h: 512 },
    "faPro_regular.plus": { w: 448, h: 512 },
    "faPro_regular.print": { w: 512, h: 512 },
    "faPro_regular.refrigerator": { w: 384, h: 512 },
    "faPro_regular.square-poll-vertical": { w: 448, h: 512 },
    "faPro_regular.trash": { w: 448, h: 512 },
    "faPro_regular.upload": { w: 512, h: 512 },
    "faPro_regular.video": { w: 576, h: 512 },
    "faPro_regular.washing-machine": { w: 448, h: 512 },
    "faPro_regular.xmark": { w: 384, h: 512 },
    "faPro_solid.backward-step": { w: 320, h: 512 },
    "faPro_solid.bolt-lightning": { w: 384, h: 512 },
    "faPro_solid.check": { w: 448, h: 512 },
    "faPro_solid.circle-check": { w: 512, h: 512 },
    "faPro_solid.circle-xmark": { w: 512, h: 512 },
    "faPro_solid.forward-step": { w: 320, h: 512 },
    "faPro_solid.pause": { w: 320, h: 512 },
    "faPro_solid.play": { w: 384, h: 512 },
} as const;

export type IconsType = keyof typeof ICONS;

declare module "@uxf/ui/icon/theme" {
    interface IconsSet {
        "arrow-left": true;
        "arrow-right": true;
        "calendar": true;
        "camera": true;
        "caretDown": true;
        "clock": true;
        "bars": true;
        "check": true;
        "chevronDown": true;
        "chevronLeft": true;
        "chevronsLeft": true;
        "chevronRight": true;
        "chevronsRight": true;
        "chevronUp": true;
        "cloud": true;
        "copy": true;
        "ellipsis-vertical": true;
        "file": true;
        "imageFile": true;
        "triangle-exclamation": true;
        "user": true;
        "videoFile": true;
        "xmark": true;
        "xmarkLarge": true;
        "circle-info": true;
        "filter": true;
        "pen": true;
        "table": true;
        "file-arrow-down": true;
        "table-columns": true;
        "search": true;
        "file-download": true;
        "trash": true;
        "warning": true;
        "faPro_light.camera-retro": true;
        "faPro_light.graduation-cap": true;
        "faPro_light.key": true;
        "faPro_light.lightbulb": true;
        "faPro_light.list-check": true;
        "faPro_light.lock-keyhole": true;
        "faPro_light.map": true;
        "faPro_light.party-horn": true;
        "faPro_light.refrigerator": true;
        "faPro_light.square-poll-vertical": true;
        "faPro_light.timer": true;
        "faPro_light.user-check": true;
        "faPro_light.user-xmark": true;
        "faPro_light.video": true;
        "faPro_light.washing-machine": true;
        "faPro_regular.angle-right": true;
        "faPro_regular.arrow-down-to-line": true;
        "faPro_regular.arrow-left": true;
        "faPro_regular.arrow-right": true;
        "faPro_regular.arrow-right-from-bracket": true;
        "faPro_regular.backward-step": true;
        "faPro_regular.bars": true;
        "faPro_regular.bolt-lightning": true;
        "faPro_regular.clock": true;
        "faPro_regular.eye": true;
        "faPro_regular.info": true;
        "faPro_regular.lightbulb": true;
        "faPro_regular.list-check": true;
        "faPro_regular.loader": true;
        "faPro_regular.map": true;
        "faPro_regular.pen": true;
        "faPro_regular.person": true;
        "faPro_regular.play": true;
        "faPro_regular.plus": true;
        "faPro_regular.print": true;
        "faPro_regular.refrigerator": true;
        "faPro_regular.square-poll-vertical": true;
        "faPro_regular.trash": true;
        "faPro_regular.upload": true;
        "faPro_regular.video": true;
        "faPro_regular.washing-machine": true;
        "faPro_regular.xmark": true;
        "faPro_solid.backward-step": true;
        "faPro_solid.bolt-lightning": true;
        "faPro_solid.check": true;
        "faPro_solid.circle-check": true;
        "faPro_solid.circle-xmark": true;
        "faPro_solid.forward-step": true;
        "faPro_solid.pause": true;
        "faPro_solid.play": true;
    }
}
