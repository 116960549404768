import { ApolloProvider } from "@apollo/client";
import { config } from "@config/config";
import { ICONS } from "@config/icons";
import { ICON_SPRITE, ICONS_VERSION } from "@config/icons-config";
import { twScreens } from "@config/tw-tokens/tw-screens";
import { LocalizeProvider } from "@localize";
import { Caveat_Brush, Montserrat, Titan_One } from "@next/font/google";
import { useApollo } from "@shared/lib/apollo";
import "@ui/styles/globals.css";
import { COLOR_SCHEME_DEFAULTS } from "@uxf/ui/color-scheme";
import { UiContextProvider, UiContextType } from "@uxf/ui/context";
import { FlashMessages, getFlashMessagesRef } from "@uxf/ui/flash-messages";
import { Message, MessageService } from "@uxf/ui/message";
import { Modal } from "@uxf/ui/modal/modal";
import { getModalRef } from "@uxf/ui/modal/modal-service";
import { locale } from "dayjs";
import "dayjs/locale/cs";
import { AppProps } from "next/app";
import Head from "next/head";

locale("cs");

const UI_PROVIDER_CONFIG: UiContextType = {
    colorScheme: {
        defaultColorScheme: "light",
        getUserSettings: COLOR_SCHEME_DEFAULTS.getUserSettings,
        respectSystemSetting: false,
        respectUserSettings: false,
        setUserSettings: COLOR_SCHEME_DEFAULTS.setUserSettings,
    },
    domain: config.FRONTEND_URL ?? "",
    icon: { spriteFilePath: `${ICON_SPRITE}?v=${ICONS_VERSION}`, iconsConfig: ICONS },
    rasterImage: { breakpoints: twScreens },
};

const fontCaveat = Caveat_Brush({
    adjustFontFallback: true,
    preload: true,
    subsets: ["latin", "latin-ext"],
    variable: "--font-caveat",
    weight: ["400"],
});

const fontMontserrat = Montserrat({
    adjustFontFallback: true,
    preload: true,
    subsets: ["latin", "latin-ext"],
    variable: "--font-montserrat",
    weight: ["400", "500", "700"],
});

const fontTitan = Titan_One({
    adjustFontFallback: true,
    preload: true,
    subsets: ["latin", "latin-ext"],
    variable: "--font-montserrat",
    weight: ["400"],
});

function UxfApp(props: AppProps) {
    const apollo = useApollo(props.pageProps);

    return (
        <>
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx global>
                {`
                    :root {
                        --font-caveat: ${fontCaveat.style.fontFamily};
                        --font-montserrat: ${fontMontserrat.style.fontFamily};
                        --font-titan: ${fontTitan.style.fontFamily};
                    }
                `}
            </style>
            <Head>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
                />
            </Head>
            <ApolloProvider client={apollo}>
                <LocalizeProvider value="cs">
                    <UiContextProvider value={UI_PROVIDER_CONFIG}>
                        <props.Component {...props.pageProps} />
                        <Message ref={MessageService.getMessageRef()} />
                        <Modal ref={getModalRef()} />
                        <FlashMessages ref={getFlashMessagesRef()} />
                    </UiContextProvider>
                </LocalizeProvider>
            </ApolloProvider>
        </>
    );
}

export default UxfApp;
